<nav class="flex px-6 justify-between font-body" [ngClass]="[bgColor | prefix: 'bg']">
  <!-- NAV ITEMS -->
  <div class="flex items-center md:flex-1">
    <div class="hidden md:flex">
      <echo-nx-nav-dropdown-item (itemClicked)="itemClicked.next($event)" [item]="navigation" [textColor]="textColor"
        [iconsVisible]="showIcons" [upperCase]="true" [fontWeight]="fontWeight" [dropdownColor]="textColor"
        [fontSize]="'xl'"></echo-nx-nav-dropdown-item>
    </div>

    <div class="flex md:hidden relative cursor-pointer" style="width: 2.25rem; height: 2.25rem"
      (click)="toggleSideNav()">
      <div class="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <span class="block absolute h-1 w-8 bg-primary-500 transform transition duration-250 ease-in-out" [ngClass]="{
            'rotate-45': sideMenuActive,
            ' -translate-y-2': !sideMenuActive
          }"></span>
        <span class="block absolute h-1 w-8 bg-primary-500 transform transition duration-250 ease-in-out"
          [ngClass]="{ 'opacity-0': sideMenuActive }"></span>
        <span class="block absolute h-1 w-8 bg-primary-500 transform transition duration-250 ease-in-out" [ngClass]="{
            '-rotate-45': sideMenuActive,
            ' translate-y-2': !sideMenuActive
          }"></span>
      </div>
    </div>
  </div>
</nav>