<nav
  class="w-full relative h-12 sm:h-20 font-body"
  [ngClass]="[primaryColor | prefix: 'bg', bgColor | prefix: 'bg']"
>
  <!-- NAV ITEMS -->
  <div class="flex sm:flex-1 items-center items-center p-2 sm:p-4">
    <div class="hidden sm:flex">
      <echo-nx-nav-dropdown-item
        (itemClicked)="itemClicked.next($event)"
        [item]="navigation"
        [textColor]="textColor"
        [iconsVisible]="showIcons"
        [fontWeight]="fontWeight"
        [dropdownColor]="textColor"
      ></echo-nx-nav-dropdown-item>
    </div>

    <div
      class="flex sm:hidden relative cursor-pointer"
      style="width: 2.25rem; height: 2.25rem"
      (click)="toggleSideNav()"
    >
      <div
        class="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
      >
        <span
          class="block absolute h-1 w-8 bg-primary-500 transform transition duration-250 ease-in-out"
          [ngClass]="{
            'rotate-45': sideMenuActive,
            ' -translate-y-2': !sideMenuActive
          }"
        ></span>
        <span
          class="block absolute h-1 w-8 bg-primary-500 transform transition duration-250 ease-in-out"
          [ngClass]="{ 'opacity-0': sideMenuActive }"
        ></span>
        <span
          class="block absolute h-1 w-8 bg-primary-500 transform transition duration-250 ease-in-out"
          [ngClass]="{
            '-rotate-45': sideMenuActive,
            ' translate-y-2': !sideMenuActive
          }"
        ></span>
      </div>
    </div>
  </div>
</nav>
