export interface AyrshareReqBody {
  post: string;
  platforms: SocialPlatforms[];
  mediaUrls?: string[];
  isVideo?: boolean;
  // UTC date time. "yyyy-MM-ddTHH:mm:ssZ", If the datetime is in the past, the post will immediately be sent.
  scheduleDate?: string;
  shortenLinks?: boolean;
  autoSchedule?: any;
  autoRepost?: any;
  requiresApproval?: boolean;
  autoHashtag?: any;
  faceBookOptions?: FacebookOptions;
  // add more as you like https://docs.ayrshare.com/rest-api/endpoints/post
}

export interface AyrshareResData {
  status: Status;
  errors: AyrshareError[];
  postIds: { status: Status, id: string, postUrl: string, platform: string }[];
  id: string;
  refId: string;
  post: string;
}


export type FacebookOptions = {
  carousel?: {
    link: string;
    items: { name: string, link: string, picture: string }[];
  },
  link?: string;
}

type AyrshareError = {
  action: string;
  status: Status;
  code: number;
  message: string;
  details: string;
  verifyCheck: boolean;
  platform: SocialPlatforms[];
}

type Status = 'success' | 'error';
type SocialPlatforms =
  "facebook"
  | "fbg"
  | "twitter"
  | "linkedin"
  | "instagram"
  | "youtube"
  | "reddit"
  | "telegram"
  | "gmb"
  | "pinterest"
  | "tiktok"
