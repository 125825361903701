import { Directive, EventEmitter, Input, Output } from "@angular/core";
import { ISearchBarDesign } from "@echo-nx/shared/common";

@Directive()
export class BaseSearchBarComponent {

  @Input()
  design: ISearchBarDesign;

  @Output()
  search: EventEmitter<string> = new EventEmitter<string>();


  public onSearchSubmit(event: SubmitEvent, text: string) {
    event.preventDefault();
    this.search.next(text);
  }
}