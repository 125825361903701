import { BaseWidgetSettings } from "./base-settings.type";
import { IBaseCalendarDesign, IBaseDatePickerData, IBaseDatePickerDesign } from "../../structural";
import { ComponentVariant } from "./component-variants";


export type DatePickerWidgetSettings = {
  variant?: ComponentVariant;
  datePickerData?: IBaseDatePickerData;
  datePickerDesign?: IBaseDatePickerDesign;
  calendarDesign?: IBaseCalendarDesign;
  targetWidget: string; //  uniqueName widgetu, ktery na to ma reagovat
  filterTemplate?: string;
  transform?: 'now' | 'end' | 'start';
} & BaseWidgetSettings;
