import { BaseDocumentType } from './base-document-types';
import { IKioskScreen } from './structural';

export interface IBaseOwner<H extends IKioskScreen | string = any, N = any>
  extends BaseDocumentType {
  owner?: IBaseOwner;
  name: string;

  homepage?: H;
  mainNavigation: N;

  integrations?: IOwnerIntegrations;

  domains?: string[]; // mainly used to generate jwt token based on request hostname
  entityTypeSlugMapping?: Record<string, string>; // dictionary from entityType to web page slug. e.g. {'events': 'palkovice-web-akce'}. used for search feature to get proper redirect

  favicon?: string;
  webTitle?: string;
}

export interface IOwnerIntegrations {
  socialSites?: ISocialSitesIntegrations;
  mailgun?: IMailgunIntegration;
  analytics?: IAnalyticsIntegrations;
}

export interface IAnalyticsIntegrations {
  gtmTag?: string;
}

export interface ISocialSitesIntegrations {
  instagram?: IFacebookIntegration;
  ayrshareApiKey?: string;
}

export interface IFacebookIntegration {
  short_lived_token?: string;
  long_lived_token?: string;
  user_id?: string;
  expires_in?: number;
  token_type?: string;
}

export interface IMailgunIntegration {
  auth: IMailgunAuth;
  host: string;
  noreplyAddress: string;
  templatePath: string;
}

export interface IMailgunAuth {
  api_key: string;
  domain: string;
}
