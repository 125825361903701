import { BaseWidgetSettings } from "./base-settings.type";
import { ContentfulSettings } from "./contentful-settings.type";
import { IBaseItemListData, IBaseItemListDesign, IBasePaginationData, } from "../../structural";
import { ComponentVariant } from "./component-variants";


export type ItemListWidgetSettings = {
  variant?: ComponentVariant;
  itemsClass?: string;
  itemTo?: string; // route for item click
  isPhotoCard?: boolean; //itemCard vs photoCard
  itemListData?: IBaseItemListData;
  itemListDesign?: IBaseItemListDesign;
  paginated: boolean;
  initialFilter?: string; // json stringified ContentFilters
  initialPagination?: IBasePaginationData;
  emptyText?: string;
  emptyIcon?: string;
  emptyClass?: string;
  withoutScroll?: boolean;
} & BaseWidgetSettings & ContentfulSettings;
