import { IEpxIcon } from "../../structural";
import { BaseWidgetSettings } from "./base-settings.type";
import { ComponentVariant } from "./component-variants";

export type SearchBarWidgetSettings = {
  variant: ComponentVariant;
  searchRoute: string;
  searchBarDesign: ISearchBarDesign;
} & BaseWidgetSettings;

export interface ISearchBarDesign {
  bgColor?: string;
  textColor?: string;
  startIcon?: IEpxIcon;
  submitIcon: IEpxIcon;
  border?: 'none' | 'bottom' | 'all';
  borderColor?: string;
  borderRadius?: string;
  placeholder?: string;
}