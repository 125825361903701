import { IEpxColorful, IEpxFluidable, IEpxIcon } from './i-base-attributes';
import { IMedium } from '../i-medium';
import { INavigationItem } from '../kiosk';

export interface IBaseNavigationListData {
  navigation?: INavigationItem;
}

export interface IBaseNavigationListDesign extends IEpxFluidable {
  hideTitle?: boolean;
  orientation?: 'col' | 'row' | 'col-reverse' | 'row-reverse';
  bgColor?: string;
  startIcon?: IEpxIcon;
  titleColor?: string;
  activeColor?: IEpxColorful;
  textColor?: string;
  hoverColorful?: IEpxColorful;
  dividerColor?: string;
  divideSize?: 'small' | 'medium' | 'large';
  applyBorder?: boolean;
}
