import { IPrivilege } from '../interfaces';

export const hasAccess = (
  neededPrivileges: IPrivilege[],
  userPrivileges: IPrivilege[]
): boolean => {
  const entityPrivileges = userPrivileges?.filter(({ name, entityType }) =>
    neededPrivileges.some(
      (np) =>
        (np.entityType === entityType || entityType === '*') && np.name === name
    )
  );

  // privileges order matter! so you can deny all, then allow one. or allow all, then deny one. no privilege specified = allow access.
  return entityPrivileges.length > 0
    ? entityPrivileges[entityPrivileges.length - 1].isAllowed
    : false;
};
