import gql from 'graphql-tag';

export const readSelectedCategory = gql`
  query ReadSelectedCategory($ids: [String!]!) {
    response: readSelectedCategory(
      ids: $ids
    ) {
      owner{
          _id
          name
        }
      _id
      name
      language
      languageGroupId
      description
      publishedAt
      featuredImage {
        _id
        name
        description
        mime
        type
        url
      }
      type
      color
      icon
      isSystemic
      priority
    }
  }
`;
