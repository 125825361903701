import gql from "graphql-tag";

export const updateSelf = gql`
  mutation updateSelf($name: String!, $oldPassword: String, $newPassword: String){
    response: updateSelf(name: $name, oldPassword: $oldPassword, newPassword: $newPassword){
      userMessage
      error
    }
  }
`;
