import { IPrivilege, IRole } from './i-role';
import { IOwnedEntity } from './i-owned-entity';
import { IOwnedContact } from './i-owned-contact';
import { IBaseOwner } from './i-base-owner';


export interface IBaseAuth<T extends IBaseOwner | string> extends IOwnedEntity<T> {
  name?: string;
  email: string;
  hashedPassword: string;
  role: IRole<T> | string;
  user?: IOwnedContact<T> | string;
  privileges?: IPrivilege[];
}

export interface IBaseAuthInput<T extends IBaseOwner | string> extends IBaseAuth<T> {
  password: string;
  role: string;
  user: IOwnedContact<T>; // input entire object, but save only ID ref
}

export interface IAuthApiResponse<T> {
  message: string;
  success: boolean;
  data?: T;

}

export interface IAuthTokens {
  token: string;
  refresh_token: string;
}