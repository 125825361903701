import { Component, HostBinding, Input } from '@angular/core';
import { IBaseButtonData, IBaseButtonDesign, IEpxColorful, IEpxIcon } from "@echo-nx/shared/common";

@Component({
  selector: 'echo-nx-button-theme-a',
  templateUrl: './button-theme-a.component.html',
  styleUrls: ['./button-theme-a.component.scss']
})
export class ButtonThemeAComponent implements IBaseButtonDesign, IBaseButtonData {

  @Input()
  orientation;

  @Input()
  bgColor: string;

  @Input()
  accentColor: string;

  @Input()
  prominent = false;

  @Input()
  dense = false;

  @Input()
  outlined = false;

  @Input()
  textColor: string;

  @Input()
  secondaryColor: string;

  @Input()
  description?: string;

  @Input()
  descriptionColorful?: IEpxColorful;

  @Input()
  title!: string;

  @Input()
  startIcon?: IEpxIcon & { offset?: boolean };

  @Input()
  endIcon?: IEpxIcon & { offset?: boolean };

  @Input()
  fluid;

  @Input()
  fontSize: string;

  @Input()
  panelClass?: string;

  @HostBinding('class') get classList(): any {
    return {
      'flex-1': this.fluid === 'horizontal' || this.fluid === 'both',
      'flex': this.fluid === 'horizontal' || this.fluid === 'both',
      'h-full': this.fluid === 'vertical' || this.fluid === 'both',
    };
  }

  @Input()
  set design({
    textColor,
    bgColor,
    startIcon,
    endIcon,
    fluid,
    descriptionColorful,
    prominent,
    orientation,
    outlined,
    dense,
    secondaryColor,
    fontSize,
    panelClass
  }: IBaseButtonDesign) {
    this.fluid = fluid;
    this.textColor = textColor;
    this.bgColor = bgColor;
    this.startIcon = startIcon;
    this.endIcon = endIcon;
    this.descriptionColorful = descriptionColorful;
    this.prominent = prominent;
    this.orientation = orientation ?? 'row';
    this.dense = dense;
    this.outlined = outlined;
    this.secondaryColor = secondaryColor;
    this.fontSize = fontSize;
    this.panelClass = panelClass;
  }


  @Input()
  set data({
    title,
    description,
  }: IBaseButtonData) {
    this.title = title;
    this.description = description;
  }
}
