import { BaseWidgetSettings } from "./base-settings.type";
import { IBaseCalendarDesign, IBaseDatePickerData } from "../../structural";
import { ComponentVariant } from "./component-variants";


export type CalendarWidgetSettings = {
  variant?: ComponentVariant;
  data: IBaseDatePickerData;
  targetWidget: string; //  uniqueName widgetu, ktery na to ma reagovat
  filterTemplate?: string;
  calendarDesign: IBaseCalendarDesign;
} & BaseWidgetSettings;
