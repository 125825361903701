import { IEpxColorful } from "./i-base-attributes";
import { IMedium } from "../i-medium";
import { INavigationItem } from "../kiosk";


export interface IBaseNavbarData {
  smallLogo?: IMedium;
  fullLogo?: IMedium | string;
  navigation?: INavigationItem;
}

export interface IBaseNavbarDesign {
  bgColor?: string;
  primaryColor?: string;
  secondaryColor?: string;
  showIcons?: boolean;
  textColor?: string;
  hoverColorful?: IEpxColorful;
  fontWeight?: 'thin' | 'extrathing' | 'light' | 'normal' | 'medium' | 'semibold' | 'bold' | 'extrabold' | 'black';
}
