import { UntypedFormGroup } from '@angular/forms';

export interface IBaseFormFieldSettings {
  nazev:
    | string
    | ((
        settings: IBaseFormFieldSettings,
        formGroup: UntypedFormGroup
      ) => string);
  transformFn?: (...args: any[]) => any; // run the transform fn on value before patching
  formControlName: string;
  defaultValue?: any;
  napoveda?: {
    isIcon?: boolean;
    text: string;
  };
  adminMode?: boolean;
  povinnost?: boolean;
  isReadonly?: boolean;
  showFunction?: (form?: UntypedFormGroup) => boolean;
}
