import { IEntityDefinition } from '../../../interfaces';
import { IBaseFormFieldSettings } from '../base-form-field.settings';

export interface IRepeaterFieldSettings extends IBaseFormFieldSettings {
  color?: string;
  expandable?: boolean;
  iconAddButton?: boolean;
  hideAddButton?: boolean;
  hideRowDeleteButton?: boolean;
  rowDefinition: IEntityDefinition[];
  withDivider?: boolean;
  withoutCard?: boolean;
  allowReorder?: boolean;
}
