import {BaseWidgetSettings} from "./base-settings.type";
import {
  ChartType
} from 'ng-apexcharts';


export type ChartWidgetSettings = {
  chartData: ChartData,
  chartDesign: ChartDesign,
} & BaseWidgetSettings;

export interface ChartDesign {

  panelClass: string,
}

export interface ChartDataSource {
  uniqueName: string;
  store: string;
  filter: string;
  sortColumn?: string;
  sortDirection?: "ASC" | "DESC";
  group?: string;
  project?: string;
}

export interface ChartData {
  title: string;
  datasets: ChartDataset[];
  sources: ChartDataSource[];

  xAxis: {
    type: 'category' | 'hour' | 'datetime' | 'numeric'
  }
}

export interface ChartDataset {
  /** references DataSource's uniqueName - if empty, will take first dataset **/
  sourceUniqueName?: string;

  chartType: ChartType;
  primaryColor?: string;

  yAxis?: {
    /** moves the yAxis to the right side */
    opposite?: boolean;
    decimalsInFloat?: number;
    seriesName?: string;
    target?: string;
    hide?: boolean;
  }

  /** MUST return object with "x" and "y" properties **/
  mapping: string;
  title: string;
  label?: string;
}
