<ng-container *ngFor="let child of item?.children; let i = index">
  <div [class.w-full]="offset" class="px-4 py-2" *ngIf="child.isActive">
    <!--[echoNxKioskRouteTo]="(child | activeChildrenCount) > 0 ? undefined : (child.screen?.slug ?? child.externalLink)"-->
    <div
      (mouseenter)="activeIndex = i"
      (mouseleave)="activeIndex = -1"
      [ngClass]="[
        child.screen?.slug || child.externalLink ? 'cursor-pointer' : '',
        (textColor ? textColor : 'primary-500') | prefix: 'text',
        fontSize ?? 'lg' | prefix: 'text'
      ]"
      class="relative font-display font-medium hover:text-secondary-500"
    >
      <!--Item content-->
      <div
        (click)="
          child.screen?.slug ?? child.externalLink
            ? itemClicked.next(child.screen?.slug ?? child.externalLink)
            : undefined
        "
        class="nav-item-content"
      >
        <span *ngIf="child.icon && iconsVisible">
          <i
            [ngClass]="[child.icon, fontSize ?? 'lg' | prefix: 'text']"
            class="text-center mt-1 w-6"
          ></i
        ></span>
        <span
          [ngClass]="[
            upperCase ? 'uppercase' : '',
            fontWeight | prefix: 'font'
          ]"
        >
          {{ child.title }}
        </span>
        <i
          *ngIf="child.externalLink"
          class="text-xs ml-1 fas fa-external-link-alt mt-1"
        ></i>
        <i
          *ngIf="(child | activeChildrenCount) > 0"
          [ngClass]="[offset ? 'fa-caret-right' : 'fa-caret-down']"
          class="mt-1 fas"
        ></i>
      </div>
      <!--Item children -->
      <div
        *ngIf="(child | activeChildrenCount) > 0"
        [class.hidden]="activeIndex !== i"
        [ngClass]="[offset ? 'left-full top-0' : '']"
        class="z-50 flex flex-col w-56 space-y-2 absolute shadow-lg bg-white ring-1 ring-slate-200"
      >
        <echo-nx-nav-dropdown-item
          (itemClicked)="itemClicked.next($event)"
          [item]="child"
          [textColor]="dropdownColor"
          [offset]="true"
          [iconsVisible]="iconsVisible"
          [upperCase]="upperCase"
        ></echo-nx-nav-dropdown-item>
      </div>
    </div>
  </div>
</ng-container>
