import { AfterViewInit, Directive, EventEmitter, Input, Output } from '@angular/core';
import { IBaseNavbarData, IBaseNavbarDesign, IEpxColorful, IMedium, INavigationItem } from "@echo-nx/shared/common";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { NavMobileComponent } from "../nav-mobile/nav-mobile.component";
import { take } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Directive()
export class BaseNavBarComponent implements IBaseNavbarData, IBaseNavbarDesign, AfterViewInit {

  @Output()
  itemClicked: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  bgColor: string;

  @Input()
  fullLogo: IMedium;

  @Input()
  hoverColorful: IEpxColorful;

  @Input()
  primaryColor: string;

  @Input()
  secondaryColor: string;

  @Input()
  smallLogo: IMedium;

  @Input()
  textColor: string;

  @Input()
  navigation: INavigationItem;

  @Input()
  showIcons?: boolean;

  @Input()
  fontWeight?: 'thin' | 'extrathing' | 'light' | 'normal' | 'medium' | 'semibold' | 'bold' | 'extrabold' | 'black';

  @Input()
  set design({ hoverColorful, textColor, primaryColor, secondaryColor, bgColor, showIcons, fontWeight }: IBaseNavbarDesign) {
    this.hoverColorful = hoverColorful;
    this.textColor = textColor;
    this.primaryColor = primaryColor;
    this.secondaryColor = secondaryColor;
    this.bgColor = bgColor;
    this.showIcons = showIcons;
    this.fontWeight = fontWeight;
  }

  @Input()
  set data({ smallLogo, fullLogo, navigation }: IBaseNavbarData) {
    this.smallLogo = smallLogo;
    this.fullLogo = fullLogo as any;
    this.navigation = navigation;
  }


  public sideMenuActive = false;
  private overlayRef: OverlayRef;
  private navPortal: ComponentPortal<NavMobileComponent>;

  constructor(private overlay: Overlay) {
  }

  ngAfterViewInit(): void {
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay.position()
        .global()
    });
    this.navPortal = new ComponentPortal(NavMobileComponent);
  }

  public toggleSideNav(force?: 'close' | 'open') {
    if (force === 'close' || this.sideMenuActive) {
      this.overlayRef.detach();

      this.sideMenuActive = false;
    } else {
      const sidebarRef = this.overlayRef.attach(this.navPortal);
      sidebarRef.instance.navigation = this.navigation;
      sidebarRef.instance.logo = this.fullLogo ?? this.smallLogo;
      sidebarRef.instance.primaryColor = this.primaryColor;
      this.overlayRef.outsidePointerEvents().pipe(take(1)).subscribe(() =>
        sidebarRef.instance.navClosed.next()
      );
      sidebarRef.instance.navClosed.pipe(take(1)).subscribe(() => {
        this.toggleSideNav('close');
      });
      sidebarRef.instance.itemClicked.pipe(takeUntil(sidebarRef.instance.navClosed)).subscribe((ic) => {
        this.itemClicked.next(ic);
        sidebarRef.instance.navClosed.emit();
      });
      this.sideMenuActive = true;
    }
  }
}
