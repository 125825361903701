import { IMarlenkaOwner } from './owner';
import {
  ICategorizedEntity,
  ICategory,
  IMeta,
  IOwnedEntity,
} from '@echo-nx/shared/common';

export interface IMEvent<
  C extends ICategory<IMarlenkaOwner> | string =
    | ICategory<IMarlenkaOwner>
    | string
> extends IOwnedEntity<IMarlenkaOwner>,
    ICategorizedEntity<C>,
    IMeta<any> {
  start: Date;
  duration: number;
  availableCapacity: number;
  maximumCapacity: number;
  language?: string;
  eventLanguage?: IMEventLanguage | string;
  eventTopic?: IMEventTopic | string;
}

export interface IMFilter {
  people: number;
  language: string;
}

export interface IMEventLanguage extends IOwnedEntity<IMarlenkaOwner> {
  name: string;
  nameShort?: string;
  warningTitle?: string;
  warningContent?: string;
}

export interface IMEventTopic extends IOwnedEntity<IMarlenkaOwner> {
  name: string;
  nameShort?: string;
  warningTitle?: string;
  warningContent?: string;
}
