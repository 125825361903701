import { Component, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrefixModule } from '../../prefix/prefix.module';
import { BaseSearchBarComponent } from '../base-search-bar.component';

@Component({
  selector: 'echo-nx-search-bar-variant-a',
  standalone: true,
  imports: [CommonModule, PrefixModule],
  templateUrl: './search-bar-variant-a.component.html',
  styleUrl: './search-bar-variant-a.component.scss',
})
export class SearchBarVariantAComponent extends BaseSearchBarComponent {
  open = signal(false);
}
