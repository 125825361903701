import {InjectionToken, Injector} from "@angular/core";
import {IAddress} from "@echo-nx/shared/common";
import {DateTime} from 'luxon';

export const createPortalInjector = (key: InjectionToken<never>, dataToPass: any, injector: Injector) => {
  return Injector.create(
    {
      providers: [
        {provide: key, useValue: dataToPass}
      ],
      parent: injector
    })
}

export const addressToString = (address: IAddress) => {
  return [
    address.street,
    address.province,
    `${address.city ?? ''} ${address.zipcode ?? ''}`,
    address.state
  ]
    .filter(val => !!val)
    .join(', ');
}

export const formatPrice = (value: string | number, formatFn?: (val: any) => any) => {
  const formatter = new Intl.NumberFormat('cs', {
    style: 'currency',
    currency: 'CZK',
  });

  const val = typeof value === "string" ? parseInt(value, 10) : value;
  return formatter.format(formatFn ? formatFn(val) : val);
}

export const isEntityPublished = (publishedAt: Date | string, unpublishedAt: Date | string): boolean => {
  if(!publishedAt) return false;
  const now = DateTime.now();
  if (typeof publishedAt === 'string'){
    return DateTime.fromISO(publishedAt) <= now && (!unpublishedAt || DateTime.fromISO(<string>unpublishedAt) >= now);
  } else {
    return (DateTime.fromJSDate(publishedAt) <= now) && (!unpublishedAt || DateTime.fromJSDate(<Date>unpublishedAt) >= now)
  }
}

export const isEntityUnpublished = (publishedAt: Date | string, unpublishedAt: Date | string): boolean => {
  if(!publishedAt || !unpublishedAt) return false;
  const now = DateTime.now();

  if (typeof publishedAt === 'string' && typeof unpublishedAt === 'string'){
    return DateTime.fromISO(publishedAt) <= now && (!unpublishedAt || DateTime.fromISO(unpublishedAt) <= now);
  } else {
    return (DateTime.fromJSDate(<Date>publishedAt) <= now) && (!unpublishedAt || DateTime.fromJSDate(<Date>unpublishedAt) <= now)
  }
}

export const formatEllipsisText = (text: string, charMaxLen: number = 56): string => text.length >= charMaxLen ? `${text.substr(0, charMaxLen)}...` : text;
