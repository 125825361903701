import { CommonPageTextItem } from './marlenka-page-settings';
import { IMedium } from '@echo-nx/shared/common';

export interface CommonpageTexts {
  footer: FooterTexts;
  buttons?: ButtonTexts;
}

export interface ButtonTexts {
  name: string;
  readMore: string;
  readLess: string;
  archiveAwards?: string;
  archiveCertificates?: string;
  archiveVideos?: string;
  archiveArticles?: string;
  otherPhotos?: string;
}

export interface FooterTexts {
  reception: CommonPageTextItem;

  service: CommonPageTextItem;
  faq: CommonPageTextItem;
  writeUs: CommonPageTextItem;
  payments: CommonPageTextItem;
  becomePartner: CommonPageTextItem;
  merchandise: CommonPageTextItem;
  customerCare: CommonPageTextItem;
  company: CommonPageTextItem;

  links: {
    privacyTerms: string;
    privacyTermsLink: string;
    newDocument: string;
    newDocumentLink: string;
    cookieTerms: string;
  };

  ico: CommonPageTextItem;
  dic: CommonPageTextItem;
  bank: CommonPageTextItem;
  dataBox: CommonPageTextItem;
  court: string;
  accountNo?: CommonPageTextItem;
  swift?: CommonPageTextItem;
  iban?: CommonPageTextItem;
  tel?: CommonPageTextItem;
  email?: CommonPageTextItem;
  web?: CommonPageTextItem;
}
