import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrefixModule } from '../../prefix/prefix.module';
import { BaseSearchBarComponent } from '../base-search-bar.component';

@Component({
  selector: 'echo-nx-search-bar-variant-nyrov',
  standalone: true,
  imports: [CommonModule, PrefixModule],
  templateUrl: './search-bar-variant-nyrov.component.html',
  styleUrl: './search-bar-variant-nyrov.component.scss',
})
export class SearchBarVariantNyrovComponent extends BaseSearchBarComponent {
}
