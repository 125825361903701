<div class="hidden lg:block items-center justify-end order-3">
  <form (submit)="onSearchSubmit($event, searchInput.value)">
    <input
      #searchInput
      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-l-lg focus:ring-primary-500 focus:border-primary-500 p-2.5 mr-4"
      placeholder="Vyhledat..."
      type="text"
    />
    <button
      type="submit"
      class="absolute top-0 bottom-0 right-0 bg-primary-500 text-white font-medium rounded-r-lg text-sm p-2.5 text-center inline-flex items-center mr-2"
    >
      <i class="fas fa-search"></i>
    </button>
  </form>
</div>

<!-- SMALL DEVICE TOGGLE BUTTON -->
<div class="lg:hidden">
  <button
    class="p-3 text-2xl"
    (click)="open.set(true)"
    [ngClass]="[
      design.submitIcon.bgColor | prefix: 'text',
      design.submitIcon.color | prefix: 'bg'
    ]"
  >
    <i [ngClass]="design.submitIcon.icon"></i>
  </button>
</div>

<!-- OVERLAY -->
@if(open()){
<div
  class="lg:hidden absolute top-0 right-0 bottom-0 flex items-center"
  [ngClass]="[design.bgColor | prefix: 'bg']"
>
  <form
    (mouseenter)="searchInput2.focus()"
    tabindex="1"
    (mouseleave)="open.set(false)"
    (submit)="onSearchSubmit($event, searchInput2.value)"
    class="border rounded-lg overflow-hidden relative"
    [ngClass]="[
      (design.border === 'bottom' ? 'b-1' : design.border === 'all' ? '1' : '0')
        | prefix: 'border',
      design.borderColor | prefix: 'border',
      design.textColor | prefix: 'text'
    ]"
  >
    @if(!!design.startIcon){
    <div
      class="absolute top-0 bottom-0 left-0 p-3 flex justify-center items-center"
      [ngStyle]="[
        design.startIcon.color | prefix: 'text',
        design.startIcon.bgColor | prefix: 'bg'
      ]"
    >
      <i [ngClass]="design.startIcon.icon"></i>
    </div>
    }
    <input
      #searchInput2
      class="bg-transparent text-sm p-2.5 mr-1"
      [ngClass]="{
        'pl-9': !!design.startIcon
      }"
      [placeholder]="design.placeholder ?? 'Vyhledat...'"
      type="text"
    />
    <button
      type="submit"
      class="absolute top-0 bottom-0 right-0 font-medium text-sm p-3 text-center inline-flex items-center"
      [ngClass]="[
        design.submitIcon.color | prefix: 'text',
        design.submitIcon.bgColor | prefix: 'bg'
      ]"
    >
      <i [ngClass]="design.submitIcon.icon"></i>
    </button>
  </form>
</div>
}
