<div class="flex flex-col gap-10">
  <!-- CATEGORIES, DATE, TITLE -->
  <div
    class="flex flex-col"
    [ngClass]="[
      titleText?.bgColor | prefix: 'bg',
      titleText?.color | prefix: 'text'
    ]"
  >
    <!-- categories, date -->
    <div
      class="flex flex-1 justify-between items-start"
      *ngIf="categories || date"
    >
      <!-- categories -->
      <div
        class="flex text-center gap-y-2 gap-x-0 sm:gap-y-0 sm:gap-x-2 flex-col sm:flex-row"
      >
        <div
          class="py-2 px-4 flex items-center justify-center text-white gap-x-2"
          [ngClass]="[cat?.color | prefix: 'bg']"
          *ngFor="let cat of categories"
        >
          <i *ngIf="cat.icon" class="fa-fw" [ngClass]="[cat?.icon]"></i>
          <span class="font-medium text-base">{{ cat.name }}</span>
        </div>
      </div>

      <!-- date -->
      <div
        class="font-medium text-sm flex items-center justify-center p-4"
        [ngClass]="[
          dateText?.bgColor | prefix: 'bg',
          dateText?.color | prefix: 'text'
        ]"
      >
        {{ date }}
      </div>
    </div>

    <!-- title -->
    <div class="text-2xl py-6 px-8 font-semibold font-display uppercase">
      {{ title }}
    </div>
  </div>

  <!-- FEATURED IMG, TEXT -->
  <div
    class="pl-0 md:pl-6"
    [ngClass]="[
      contentColorful?.bgColor | prefix: 'bg',
      contentColorful?.color | prefix: 'text'
    ]"
  >
    <div class="max-w-lg pb-4 px-4 float-right" *ngIf="image">
      <img [src]="image.url" [alt]="image.alt" />
    </div>
    <div
      class="prose max-w-none"
      style="color: inherit"
      [innerHtml]="content"
    ></div>
  </div>

  <!-- images -->
  <div *ngIf="images" class="pl-0 md:pl-6">
    <div
      class="py-2 text-2xl font-medium font-display"
      [ngClass]="[secondaryColor | prefix: 'text']"
    >
      GALERIE
    </div>
    <div class="grid gap-4 grid-cols-2 lg:grid-cols-4 cursor-pointer">
      <div
        class="relative"
        *ngFor="let image of images"
        (click)="attachmentClick.emit(image._id)"
      >
        <img
          class="h-40 w-full object-cover"
          [src]="image.url"
          [alt]="image.description"
        />
      </div>
    </div>
  </div>

  <!-- DOCUMENTS -->
  <div
    class="pl-0 md:pl-6 flex flex-col"
    [ngClass]="[secondaryColor | prefix: 'text']"
    *ngIf="documents.length > 0"
  >
    <div class="py-2 text-2xl font-medium font-display">DOKUMENTY</div>

    <div class="grid grid-cols-1 gap-2">
      <div
        *ngFor="let doc of documents"
        [ngClass]="[primaryColor | prefix: 'bg']"
        class="flex flex-row items-center px-6 py-4 gap-4 cursor-pointer"
        (click)="attachmentClick.emit(doc._id)"
      >
        <i
          class="text-3xl"
          [ngClass]="mimeTypeIconDict[doc.mime] ?? 'far fa-file'"
        ></i>
        <div class="flex flex-1 flex-col">
          <span class="font-medium font-display text-xl">{{ doc.name }}</span>
          <span class="font-body text-sm text-primary-200">
            {{ doc.originalFileName }}.{{ mimeTypeExtensionDict[doc.mime] }}
          </span>
          <span *ngIf="doc.metadata?.size">
            ({{ doc.metadata.size / 1000 }}kB)
          </span>
        </div>
        <div class="flex flex-row gap-4 text-primary-200 items-center">
          <span class="font-medium font-display">Zobrazit</span>
          <i class="text-2xl far fa-eye"></i>
        </div>
      </div>
    </div>
  </div>
</div>
