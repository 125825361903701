import { IMarlenkaOwner } from './owner';
import { IMEvent } from './event';
import { IMProductType, IMReservedProduct } from './product';
import { IMReservedTicket, IMTicketType } from './ticket';
import { IMReservedContact } from './person';
import { IDiscountCoupon } from './discount-coupon';
import { IMVoucherType } from './voucher-type';
import {
  IOwnedEntity,
  ITransaction,
  PaymentInfo,
} from '@echo-nx/shared/common';

// rezervace pro sebe
export interface IMReservation<
  T extends IMTicketType | string = IMTicketType | string,
  P extends IMProductType | string = IMProductType | string,
  E extends IMEvent | string = IMEvent | string
> extends IMOrder<T, P> {
  invoiceCode?: number;
  reservedTill?: Date;
  coupons?: IDiscountCoupon[] | any;
  event?: E;
  glutenFreeCount?: number;
  canBeChangedByUser?: boolean;
  language?: string;
}

// kupon k rezervaci pro nekoho
export interface IMReservationVoucher<
  T extends IMTicketType | string = IMTicketType | string,
  P extends IMProductType | string = IMProductType | string,
  M extends IMVoucherType | string = IMVoucherType | string
> extends IMOrder<T, P> {
  code?: string; // code to enter for reservations
  text?: string;
  title?: string;
  signature?: string;
  voucherType?: M;
  reservationHistory?: IMReservation[] | string[]; // vsechny rezervace, ktere vyuzily tento kupon
}

// sjednocuje Rezervaci a Kupon
export interface IMOrder<
  T extends IMTicketType | string = IMTicketType | string,
  P extends IMProductType | string = IMProductType | string
> extends IOwnedEntity<IMarlenkaOwner | string> {
  tickets?: IMReservedTicket<T>[];
  products?: IMReservedProduct<P>[];
  contact?: IMReservedContact;
  total?: number; // before create
  transactions?: ITransaction<PaymentInfo>[] | string[];
  uniqueId?: string;
  uniqueCode?: string; // random short code in case of telecomunication between user and marlenka
}
