import { IMedium } from '../i-medium';
import { IKioskOwner } from '../kiosk';

export type IBackgroundObject = {
  color?: string;
  panelClass?: string;
  image?: IMedium<IKioskOwner> | string;
  size?: SizeOption;
  position?: PositionOption;
  repeat?: RepeatOption;
  origin?: OriginOption;
  clip?: ClipOption;
  attachment?: AttachmentOption;
  withoutGradient?: boolean;
};

export type SizeOption =
  | string
  | 'auto'
  | 'cover'
  | 'contain'
  | 'initial'
  | 'inherit';
export type RepeatOption =
  | 'repeat'
  | 'repeat-x'
  | 'repeat-y'
  | 'no-repeat'
  | 'initial'
  | 'inherit';
export type OriginOption =
  | 'padding-box'
  | 'border-box'
  | 'content-box'
  | 'initial'
  | 'inherit';
export type ClipOption =
  | 'border-box'
  | 'padding-box'
  | 'content-box'
  | 'initial'
  | 'inherit';
export type AttachmentOption =
  | 'scroll'
  | 'fixed'
  | 'local'
  | 'initial'
  | 'inherit';
export type PositionOption =
  | 'left top'
  | 'left center'
  | 'left bottom'
  | 'right top'
  | 'right center'
  | 'right bottom'
  | 'center top'
  | 'center center'
  | 'center bottom';
