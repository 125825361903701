import {BaseWidgetSettings} from "./base-settings.type";
import {IEpxFluidable} from "../../structural";


export interface IBusDepartureDesign extends IEpxFluidable {
  /**limits number of connections... if missing will get whole table from API**/
  maxLines: number;

  bgColor: string;

  isDark: boolean;

  lineBg: string;

  /** for striped tables **/
  oddLineBg: string;

  primaryColor: string;

  /** will show green arrow when the bus is in ongoing state (can be shown on map)**/
  showOngoing: boolean;

  /** Will try to modify header of table from API (rename fields, better design)
   * this should be ON by default and only disabled when/if MPVNet makes structural changes **/
  modifyHeader: boolean;

  panelClass: string;
}

export interface IBusDepartureData  {
  /**
   * https://www.mpvnet.cz/odis/tab
   * @desc mpvnet's sStopKey - three comma separated values - Train/Bus,Direction,ID
   * @example - 2,0,25832
   * // 2,0,2583 "Palkovice,Myslík,kaple"
   * // 2,0,25834 "Palkovice,,rest.U Tomisů"
   * **/
  stopId: string;

  mode: 'Departures' | 'Arrivals';

  /** API Endpoint to get data from (<APIENDPOINT>/bus-departure)**/
  endpoint: string;

  /** Basically TITLE */
  stationName: string;
}

export type BusDepartureWidgetSettings = {
  busDepartureData: IBusDepartureData;
  busDepartureDesign: IBusDepartureDesign;
} & BaseWidgetSettings;
