import { Injectable } from '@angular/core';
import { IUrlSettings, UrlCreationSettings } from '../utils';
import {
  IEnvironmentAPISettings,
  IEnvironmentKioskSettings,
} from '@echo-nx/shared/common';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfigService implements IUrlSettings {
  private _initialized$ = new BehaviorSubject<boolean>(false);
  initialized$ = this._initialized$.asObservable();
  appVersion?: string;
  apiSettings!: IEnvironmentAPISettings;
  cmsBaseUrl?: string;
  kioskSettings?: IEnvironmentKioskSettings;
  auth?: {
    loginRedirect?: string;
  };
  appendTokenIgnoreOperations?: string[];
  appendTokenIgnoreUrls?: string[];
  ssrTokenPath?: string;
  isWeb?: boolean;
  accountSettingsRoute?: string;

  get fullApiPath() {
    return `${this.apiSettings.protocol}://${this.apiSettings.apiUrl}`;
  }

  get fullGraphqlPath() {
    return `${this.fullApiPath}${this.apiSettings.graphqlSuffix}`;
  }

  get wssPath() {
    return `${this.kioskSettings?.wssProtocol}://${this.apiSettings.apiUrl}${this.kioskSettings?.wssSuffix}`;
  }

  async init(appVersion: string) {
    const config: UrlCreationSettings = await fetch('configuration.json').then(
      (response) => response.json()
    );
    console.log('FETCHED CONFIG', config);
    const { apiSettings, kioskSettings, isWeb, cmsBaseUrl, accountSettingsRoute } = config;
    this.apiSettings = apiSettings;
    this.kioskSettings = kioskSettings;
    this.appVersion = appVersion;
    this.isWeb = isWeb;
    this.cmsBaseUrl = cmsBaseUrl;
    this._initialized$.next(true);
    this.accountSettingsRoute = accountSettingsRoute;
  }
}
