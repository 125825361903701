export interface FetchAllArgs {
  search?: string;
  page?: number;
  take?: number;
  sortColumn?: string;
  sortDirection?: 'ASC' | 'DESC';
  filter?: string;
  includeNotPublished?: boolean;
  includeDeleted?: boolean;

  group?: string;
  project?: string;
}

