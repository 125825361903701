import gql from 'graphql-tag';

export const readAllArticleForSynchronizing = gql`
  query ReadAllArticle(
    $search: String
    $page: Int
    $take: Int
    $sortColumn: String
    $sortDirection: String
    $filter: String
    $includeDeleted: Boolean = true
  ) {
    response: readAllArticle(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      filter: $filter
      includeDeleted: $includeDeleted
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        title
        slug
        summary
        content
        categories {
          _id
          name
          color
          icon
        }
        owner {
          _id
          name
        }
        featuredImage {
          _id
          url
          thumbnailUrl
          type
          alt
        }
        attachments {
          _id
          mime
          type
          url
          name
          thumbnailUrl
          description
          createdAt
          originalFileName
          alt
        }
        createdAt
        deletedAt
        publishedAt
        unpublishedAt
        language
        languageGroupId
      }
    }
  }
`;
