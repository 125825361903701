import {Widget, WidgetName} from './widget.type';
import {FlexLayoutSettings} from "./layout-setting";

export type WidgetEvent<T> = {
  eventName: 'drop' | 'click' | 'right-click';
  name: WidgetName; // to know the type widget that has been clicked or dropped or whatever. used in CMS to spawn appropriate settings form
  uniqueName: string; // widget uniqueName in the tree
  purpose?: 'open-context' | 'open-bottom-sheet' | 'open-document-viewer' | 'reload' | 'open-dropdown' | 'clear-active-filters'; // what is the purpose ? openBottomSheet, openDocumentViewer etc.
  data: T;
}

// todo rename - this could be called something else, its not tied to sheet anymore
export type SheetEventData = {
  name: string; // most likely what component to spawn somewhere
}

export type DropdownEventData = {
  widgets: Widget[];
  panelClass?: string;
  positions?: PositionPair[]
  flexLayoutSettings?: FlexLayoutSettings;
}

// mock angular ConnectionPositionPair. cant import angular here in shared-common
interface PositionPair {
  origin: OriginPosition;
  overlay: OverlayPosition;
}
type HorizontalConnectedPos = 'start' | 'center' | 'end';
type VerticalConnectedPos = 'top' | 'center' | 'bottom';
interface OriginPosition {
  originX: HorizontalConnectedPos;
  originY: VerticalConnectedPos;
}
interface OverlayPosition {
  overlayX: HorizontalConnectedPos;
  overlayY: VerticalConnectedPos;
}
