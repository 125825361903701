import { IKioskTimeline } from './timeline';
import { IRole } from '../i-role';
import { IKioskBrowserSettings } from './kiosk';

export type KioskAuthRequestBody = {
  pwaUniqueId?: string;
  electronUniqueId?: string;
  appVersion?: string;
};

export type KioskAuthToken = {
  _id: string; // ws-client-id (pwaUniqueId or electronUniqueId or CMS auth _id)
  browserSettings: IKioskBrowserSettings;
  homescreenId: string;
  idleTimeout?: number;
  kioskId: string;
  macAddress?: string;
  ownerId: string;
  pin?: string;
  pwaUniqueId: string;
  electronUniqueId?: string;
  role?: IRole<any>;
  timeline?: IKioskTimeline<any>;
  timelineTimeout?: number;
  externalUrl?: string;
};
