import { IOwnedEntity } from "./i-owned-entity";
import { IBaseOwner } from './i-base-owner';
import { IMeta } from "./i-meta";

export type IRole<T extends IBaseOwner | string> = IOwnedEntity<T> & IBaseRole & IMeta<never>;

export interface IBaseRole {
  name: string;
  description?: string;
  assignableRoles: IBaseRole[] | string[];
  privileges: IPrivilege[];
  cmsHomePath?: string;
}


//THis may be separate document.. currently is embedded
export interface IPrivilege {
  name: PrivilegeName | string; // todo enum? // todo canReadDeleted
  description?: string;
  isAllowed?: boolean;

  entityType?: string; //EntityType, must be same as ResourceName aka schemaName in documentManager
  categories?: string[];

  // extended filtering
  customFilterValues?: string[];
}

export type PrivilegeName = 'read' | 'readDeleted' | 'readUnpublished' | 'update' | 'create' | 'delete' | 'forceDelete';
