import {BaseWidgetSettings} from "./base-settings.type";

export type WebSettings = {
  url: string;
  fluid?: boolean;
  width?: number | string;
  height?: number | string;
  panelClass?: string;
  scrolling?: 'yes' | 'no' | 'auto' | string;
} & BaseWidgetSettings;
