import { Component, Input } from '@angular/core';
import { FontFamilyType, IBaseTextData, IBaseTextDesign, IEpxIcon } from "@echo-nx/shared/common";

@Component({
  selector: 'echo-nx-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.css']
})
export class TextComponent implements IBaseTextData, IBaseTextDesign {

  @Input()
  endIcon?: IEpxIcon;

  @Input()
  primaryColor?: string;

  @Input()
  secondaryColor?: string;

  @Input()
  startIcon?: IEpxIcon;

  @Input()
  title?: string;

  @Input()
  fontSize?: string;

  @Input()
  fontWeight?: string;

  @Input()
  fontFamilyType?: FontFamilyType;

  @Input()
  isWysiwyg?: boolean;

  @Input()
  set data(data: IBaseTextData) {
    const { title } = data ?? {};
    this.title = title;
  }


  @Input()
  set design({ primaryColor, startIcon, endIcon, secondaryColor, fontSize, fontWeight, fontFamilyType, isWysiwyg }: IBaseTextDesign) {
    this.primaryColor = primaryColor;
    this.startIcon = startIcon;
    this.endIcon = endIcon;
    this.secondaryColor = secondaryColor;
    this.fontSize = fontSize;
    this.fontWeight = fontWeight;
    this.fontFamilyType = fontFamilyType;
    this.isWysiwyg = isWysiwyg;
  }
}
